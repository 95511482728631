<div class="wrapper" (click)="loading = true">
  <div class="frame">
    <div class="row">
      <div class="col col-3 set">
        <div class="image" [ngClass]="{'loading': loading}" *ngIf="mainImage" [ngStyle]="{ 'background-image': 'url(' + mainImage + ')'}">
          <fa-icon [icon]="fa.faSyncAlt" *ngIf="loading"></fa-icon>
        </div>
        <div class="skel-loading image" *ngIf="!mainImage"></div>
      </div>
      <div class="col col-9 info">
        <h2>{{title}}</h2>
        <p>{{companyName || ''}}<fa-icon [icon]="fa.faCheckCircle" *ngIf="companyName"></fa-icon></p>
        <p [title]="date | utcToClientTimezone">{{date | customDateTime}}</p>
      </div>
    </div>
  </div>
</div>
