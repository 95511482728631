import {Component, Input, OnInit} from '@angular/core';
import {EnvService} from '../../../services/env.service';
import * as icons from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-frames-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  public fa = icons;
  @Input() title;
  @Input() date;
  @Input() id;
  @Input() companyName;
  @Input() version;
  public mainImage;
  public loading;

  constructor(private envService: EnvService) {
  }

  ngOnInit(): void {
    this.loading = false;
    this.mainImage = `${this.envService.imageUrl}/articles/${this.id}/images/${this.version + '.main'}`;
  }
}
