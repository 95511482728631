import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TruncatePipe} from './pipes/truncate.pipe';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {EnvServiceProvider} from './services/env.service.provider';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {LandingComponent} from './modules/landing/landing.component';
import { MainComponent } from './components/frames/main/main.component';
import { SponsoredComponent } from './components/frames/sponsored/sponsored.component';
import { ImageComponent } from './components/frames/image/image.component';
import { ArticleComponent } from './modules/article/article.component';
import { CardComponent } from './components/frames/card/card.component';
import { EditComponent } from './modules/edit/edit.component';
import { OrganisationComponent } from './modules/organisation/organisation.component';
import { AuthComponent } from './components/auth/auth.component';
import {AuthGuard} from './auth/auth.guard';
import {TokenInterceptor} from './auth/token.interceptor';
import {FIREBASE_OPTIONS} from '@angular/fire/compat';
import {firebaseOptionsFactory} from './services/firebaseoptions.service.provider';
import {EnvService} from './services/env.service';
import {AlertComponent} from './components/alert/alert.component';
import {AutoresizeDirective} from './directives/autoresize.directive';
import {PlaceholderDirective} from './directives/placeholder.directive';
import {ProfileSettingsComponent} from './modules/profile-settings/profile-settings.component';
import {ProfSetTabGeneralComponent} from './modules/profile-settings/profile-settings-tabs/prof-set-tab-general/prof-set-tab-general.component';
import {ProfSetTabOrganisationsComponent} from './modules/profile-settings/profile-settings-tabs/prof-set-tab-organisations/prof-set-tab-organisations.component';
import { LoginComponent } from './components/login/login.component';
import {LandingYouComponent} from './modules/landing/pages/landing-you/landing-you.component';
import {LandingFrontComponent} from './modules/landing/pages/landing-front/landing-front.component';
import {LandingLatestComponent} from './modules/landing/pages/landing-latest/landing-latest.component';
import {UrlAnchorPipe} from './pipes/url-anchor.pipe';
import {OrganisationSettingsComponent} from './modules/organisation-settings/organisation-settings.component';
import {OrgSetTabGeneralComponent} from './modules/organisation-settings/organisation-settings-tabs/org-set-tab-general/org-set-tab-general.component';
import {OrgSetTabRolesComponent} from './modules/organisation-settings/organisation-settings-tabs/org-set-tab-roles/org-set-tab-roles.component';
import {OrgSetTabBillingComponent} from './modules/organisation-settings/organisation-settings-tabs/org-set-tab-billing/org-set-tab-billing.component';
import { CompanySelectorComponent } from './components/company-selector/company-selector.component';
import { TopicSelectorComponent } from './components/topic-selector/topic-selector.component';
import {CustomDateTimePipe} from './pipes/customdatetime.pipe';
import {UtcToClientTimezonePipe} from './pipes/utcToTimezone.pipe';
import { SignupComponent } from './components/signup/signup.component';
import {ProfSetTabInfoComponent} from './modules/profile-settings/profile-settings-tabs/prof-set-tab-info/prof-set-tab-info.component';
import {ProfSetTabSubscriptionsComponent} from './modules/profile-settings/profile-settings-tabs/prof-set-tab-subscriptions/prof-set-tab-subscriptions.component';
import {TopicComponent} from './modules/topic/topic.component';
import {LandingFeedComponent} from './modules/landing/pages/landing-feed/landing-feed.component';
import {ProfSetTabHistoryComponent} from './modules/profile-settings/profile-settings-tabs/prof-set-tab-history/prof-set-tab-history.component';
import {ArticleAnalyticsComponent} from './modules/article-analytics/article-analytics.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DatetimetransformPipe} from './pipes/datetimetransform.pipe';
import {PrivacyPolicy} from './singles/privacy-policy';
import {CookiePolicy} from './singles/cookie-policy';
import {TermsOfUse} from './singles/terms-of-use';
import {Contact} from './singles/contact';
import {StatementComponent} from './components/frames/statement/statement.component';
import {StatementHolderComponent} from './modules/statement-holder/statement-holder.component';
import {StatementEditComponent} from './modules/statement-edit/statement-edit.component';
import {EditorSelectorComponent} from './components/editor-selector/editor-selector.component';
import {UnixToDatePipe} from './pipes/unixToDate.pipe';
import {AmountNumberPipe} from './pipes/amountNumberPipe.pipe';
import {CurrencySymbolPipe} from './pipes/currencySymbol.pipe';
import {CapitalizeFirstLetterPipe} from './pipes/capitalizeFirstLetter.pipe';
import {ReplaceUnderscoreWithSpacePipe} from './pipes/replaceUnderscoreWithSpace.pipe';
import {NewOrganisationComponent} from './modules/new-organisation/new-organisation.component';
import {UtcToDatePipe} from './pipes/utcToDate.pipe';
import {VerifyEmailComponent} from './components/verify-email/verify-email.component';
import {SearchComponent} from './modules/search/search.component';
import {AiSearchComponent} from './modules/ai-search/ai-search.component';
import {AboutComponent} from './modules/about/about.component';
import {delayloadDirective} from './directives/delayload.directive';


@NgModule({
  declarations: [
    AppComponent,
    PrivacyPolicy,
    CookiePolicy,
    TermsOfUse,
    Contact,
    UtcToDatePipe,
    AboutComponent,
    delayloadDirective,
    VerifyEmailComponent,
    TruncatePipe,
    CustomDateTimePipe,
    DatetimetransformPipe,
    UtcToClientTimezonePipe,
    UnixToDatePipe,
    AmountNumberPipe,
    CurrencySymbolPipe,
    CapitalizeFirstLetterPipe,
    ReplaceUnderscoreWithSpacePipe,
    NewOrganisationComponent,
    UrlAnchorPipe,
    HeaderComponent,
    FooterComponent,
    LandingComponent,
    MainComponent,
    SponsoredComponent,
    ImageComponent,
    StatementComponent,
    StatementHolderComponent,
    StatementEditComponent,
    EditorSelectorComponent,
    ArticleComponent,
    LandingFeedComponent,
    ProfileSettingsComponent,
    CardComponent,
    EditComponent,
    OrganisationComponent,
    AuthComponent,
    AlertComponent,
    AutoresizeDirective,
    PlaceholderDirective,
    ProfSetTabGeneralComponent,
    ProfSetTabSubscriptionsComponent,
    ProfSetTabOrganisationsComponent,
    ProfSetTabInfoComponent,
    ProfSetTabHistoryComponent,
    ArticleAnalyticsComponent,
    OrganisationSettingsComponent,
    OrgSetTabGeneralComponent,
    OrgSetTabRolesComponent,
    OrgSetTabBillingComponent,
    LoginComponent,
    TopicComponent,
    SearchComponent,
    AiSearchComponent,
    LandingYouComponent,
    LandingFrontComponent,
    LandingLatestComponent,
    CompanySelectorComponent,
    TopicSelectorComponent,
    SignupComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    DragDropModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularFireAuthModule,
    DragDropModule,
    NgxChartsModule,
    BrowserAnimationsModule
  ],
    exports: [FontAwesomeModule],
  providers: [EnvServiceProvider,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: FIREBASE_OPTIONS,
      useFactory: firebaseOptionsFactory,
      deps: [EnvService]
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
