<footer xmlns="http://www.w3.org/1999/html">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ready">
        Wireplate {{ls.lo().by}} <a target="_blank" href="https://inphent.com/"><img src="assets/images/circle_no_bg100x100.png" alt="inphent">Inphent</a>.<br>
        KVK 88169227 | Copyright © 2024 Inphent. <br>{{ls.lo().All_rights_reserved}}.<br>
        {{ls.lo().NOTICE_disclaimer}}
      </div>
      <div class="col-sm-12 col-md-3 col-lg-2 col-xl-2">
      </div>
      <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 link">
        <h6>{{ls.lo().Policies}}</h6>
        <a [routerLink]="['/privacy-policy']">{{ls.lo().Privacy_policy}}</a>
        <a [routerLink]="['/terms-of-use']">{{ls.lo().Terms_of_use}}</a>
        <a [routerLink]="['/cookie-policy']">{{ls.lo().Cookie_policy}}</a>
        <a target="_blank" href="https://inphent.com/">{{ls.lo().Your_data}} <fa-icon [icon]="fa.faExternalLinkAlt"></fa-icon></a>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-2 col-xl-2 link">
        <h6>{{ls.lo().Support}}</h6>
        <a target="_blank" href="https://github.com/Inphent/wireplate-support">{{ls.lo().Help_Support}} <fa-icon [icon]="fa.faExternalLinkAlt"></fa-icon></a>
        <a [routerLink]="['/contact']">{{ls.lo().Contact}}</a>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-2 col-xl-2 link">
        <h6>{{ls.lo().Information}}</h6>
        <a [routerLink]="['/about']">Wireplate</a>
        <a target="_blank" href="https://inphent.com/">Inphent <fa-icon [icon]="fa.faExternalLinkAlt"></fa-icon></a>
      </div>
    </div>
  </div>
</footer>
