import {Component, OnInit} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {AlertService} from '../../services/alert.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    public fa = icons;
    public alert: { content: string, title: string, type: string } = {content: '', title: '', type: ''};

    constructor(public alertService: AlertService) {
    }

    ngOnInit(): void {
    }

}
