import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {catchError, filter, finalize, from, Observable, switchMap, take, throwError} from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (typeof localStorage === 'undefined') {
      return next.handle(request) as Observable<HttpEvent<any>>; // Type assertion
    }

    const token = localStorage.getItem('token');

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          return from(this.authService.refreshToken()).pipe(
            switchMap((newToken: string) => {
              if (newToken) {
                localStorage.setItem('token', newToken);
                // Clone the request with the new token
                request = request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${newToken}`
                  }
                });
                // Retry the request with the new token
                return next.handle(request) as Observable<HttpEvent<any>>; // Type assertion
              }
              // If no new token was returned, throw the original error
              return throwError(error);
            })
          );
        }
        // If the error was not a 401 or 403, just throw it
        return throwError(error);
      })
    );
  }
}
