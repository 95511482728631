import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as icons from '@fortawesome/free-solid-svg-icons';
import {AuthService} from '../../auth/auth.service';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit {
  @Output() valueEvent = new EventEmitter();
  @Input() preSelectedCompanyId;

  public fa = icons;
  public companies = [];
  public selectedCompany;
  public expanded = false;

  constructor(private authService: AuthService, private dataService: DataService) {
  }

  ngOnInit(): void {
    if (!this.preSelectedCompanyId) {
      this.authService.changeReadyAuth.subscribe(response => {
        if (response) {
          for (const com of response) {
            this.dataService.getCompany(com.company).subscribe(info => {
              this.companies.push(info);
            });
          }
        }
      });
    } else {
      this.dataService.getCompany(this.preSelectedCompanyId).subscribe(response => {
        this.selectedCompany = response;
      });
    }
  }

  selectCompany(company) {
    this.selectedCompany = company;
    this.valueEvent.emit(company);
  }

  showSelector() {
    if (this.preSelectedCompanyId) {
      return this.selectedCompany;
    } else {
      return true;
    }
  }
}
